import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { slide as Menu } from 'react-burger-menu';

const StyledNavbar = styled(Navbar)`
  .nav-link {
    font-weight: bold;
  }
`;

const MenuLink = styled(Link)`
  font-weight: bold;
`;

const Header = () => {
  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(
          relativePath: { eq: "logo.png" }
          sourceInstanceName: { eq: "images" }
        ) {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );
  const [isExpandMenu, toggleMenu] = useState(false);
  return (
    <header>
      <StyledNavbar
        bg="primary"
        variant="dark"
        expand="lg"
        expanded={false}
        onToggle={expanded => toggleMenu(expanded)}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo.childImageSharp.fluid.src} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => toggleMenu(true)} />
          <Navbar.Collapse>
            <Nav className="mr-auto">
              <div className="collapse-close">
                <button
                  type="button"
                  className="navbar-toggler"
                  onClick={() => toggleMenu(false)}
                >
                  <span />
                  <span />
                </button>
              </div>
              <Nav.Link as={Link} to="/">
                ホーム
              </Nav.Link>
              <Nav.Link as={Link} to="/companies">
                求人掲載企業
              </Nav.Link>
              <Nav.Link as={Link} to="/about">
                foriio Jobsについて
              </Nav.Link>
              <Nav.Link href="https://www.foriio.com" target="_blank">
                foriioへ
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link
                as={Link}
                to="/hire"
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <Button variant="neutral">求人を投稿する</Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </StyledNavbar>
      <Menu
        isOpen={isExpandMenu}
        onClose={() => toggleMenu(false)}
        customBurgerIcon={false}
        right
      >
        <MenuLink to="/">ホームページ</MenuLink>
        <MenuLink to="/companies">求人掲載企業</MenuLink>
        <MenuLink to="/hire">求人を投稿する</MenuLink>
        <MenuLink to="/about">foriio Jobsについて</MenuLink>
        <MenuLink href="https://www.foriio.com" target="_blank">
          foriioへ
        </MenuLink>
      </Menu>
    </header>
  );
};

export default Header;
