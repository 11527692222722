import React from 'react';
import styled, { css } from 'styled-components';
import Container from 'react-bootstrap/Container';
import Header from './Header';

const StyledContainer = styled(Container)`
  ${({ compact }) =>
    compact &&
    css`
      max-width: 800px;
    `}
`;

const MainContainer = styled.div`
  margin: 30px 0;
`;

const Layout = ({ className, compact, children }) => {
  return (
    <div>
      <Header />
      <MainContainer>
        <StyledContainer className={className} compact={compact}>
          {children}
        </StyledContainer>
      </MainContainer>
    </div>
  );
};

export default Layout;
